import React, { useState, useRef } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import "../../page/Home/style.css";
import { Box, Modal } from "@mui/material";
import leftimg from "../../assets/newheader.jpeg";
import styled from "styled-components";
import { InlineWidget } from "react-calendly";
import { FiXCircle } from "react-icons/fi";

const Button = styled.button`
  color: white;
  width: 141px;
  height: 41px;
  background-color: rgb(1, 73, 124);
  border: none;
  font-weight: bold;
  font-size: 1.2rem;
  border-radius: 4px;
`;
const Header = () => {
  const isMobile = useMediaQuery("(min-width:768px)");
  const [isOpen, setIsOpen] = useState(false);

  const HideTwo = styled.div`
    @media screen and (max-width: 1840px) {
      display: none;
    }
  `;
  const ShowTwo = styled.div`
    @media screen and (min-width: 1840px) {
      display: none;
    }
  `;

  const NewMainDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100vh;
    background-image: url(${leftimg});
    background-color: #f7f7f7;
    background-repeat: no-repeat;
    position: relative;
    background-position-x: 115%;

    background-size: contain;
    top: -50px;
    /* left:50%; */

    @media only screen and (max-width: 1300px) {
      background-position-x: 115%;
    }
    @media only screen and (max-width: 1100px) {
      background-position-x: 130%;
    }
    @media only screen and (max-width: 900px) {
      background-position-x: 150%;
    }
    @media only screen and (max-width: 800px) {
      background-position-x: 180%;
    }
    @media only screen and (max-width: 768px) {
      margin-bottom: 15rem;
    }
    @media only screen and (max-width: 768px) {
      background-image: none;
      height: 105vh;
    }
    @media screen and (max-width: 644px) {
      text-align: center !important;
    }
    @media screen and (max-width: 400px) {
      text-align: center !important;
    }
  `;
  const NewContent = styled.div`
    position: absolute;
    left: 5%;
  `;

  const NewTitle = styled.h3`
    font-size: 25pt;
    color: black;
    font-weight: 700;
    padding-top: 2rem;
    @media screen and (max-width: 644px) {
      font-size: 20pt;
      font-size: 25px;
      text-align: center;
    }
    @media screen and (max-width: 400px) {
      padding-top: 8rem;
      font-size: 25px;
      text-align: center;
    }
  `;
  const NewDescription = styled.p`
    font-size: 16px;
    color: black;
    font-weight: 500;
    width: 75%;
    @media screen and (max-width: 1600px) {
      font-size: 16px;
      width: 55%;
    }
    @media screen and (max-width: 1300px) {
      width: 98%;
      font-size: 16px;
    }
    @media screen and (max-width: 644px) {
      text-align: center !important;
    }
    @media screen and (max-width: 400px) {
      text-align: center !important;
    }
  `;
  const ag = useRef();

  return (
    <>
      <div
        style={{ paddingLeft: isMobile == false ? "0rem" : "0rem" }}
        ref={ag}
      >
        {" "}
        <Modal
          open={isOpen}
          onClose={() => setIsOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            md={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              margin: "0px",
              padding: "opx",
            }}
          >
            <div style={{ height: "50%", padding: "0px", margin: "0px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "0rem",
                  marginTop: "0.5rem",
                }}
              >
                <FiXCircle
                  size={34}
                  color={"white"}
                  onClick={() => setIsOpen(false)}
                />
              </div>
              <InlineWidget
                styles={{ height: "600px" }}
                url="https://calendly.com/codeclanio/30min"
              />
            </div>
          </Box>
        </Modal>
        <NewMainDiv>
          <NewContent>
            <HideTwo>
              <NewTitle>We Understand Your Tech</NewTitle>
            </HideTwo>

            <ShowTwo>
              <NewTitle>We Understand Your Tech</NewTitle>
            </ShowTwo>
            <NewDescription
              style={{
                textAlign: "justify",
                hyphens: "auto",
                wordSpacing: "-0.05rem",
                color: "#5c7173",
                marginTop: "2rem",
                marginBottom: "2rem",
                fontSize: "16px",
              }}
            >
              Code Clan is a group of highly skilled tech gurus all geared up to
              bring your business up to speed with the latest market trends.
              <br />
              <br /> Do not believe us?
              <br /> Book a discovery call with CodeClan for free consultation
              <br />
              right now!
            </NewDescription>
            <Button
              style={{ marginTop: "1rem" }}
              className="button-container-hireUs"
              onClick={() => setIsOpen(true)}
            >
              Get a quote
            </Button>
          </NewContent>
        </NewMainDiv>
      </div>
    </>
  );
};

export default Header;
