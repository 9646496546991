import React from "react";
import { Grid } from "@mui/material";
import styled from "styled-components";
import { useState } from "react";
import pos from "../../assets/pos_cover.webp";
import cdl from "../../assets/cdl.webp";
import pirny from "../../assets/pinry_cover.webp";
import pinryModal from "../../assets/pinryModal.webp";
import truck from "../../assets/truck_cover.webp";
import medi from "../../assets/medi_cover.webp";
import covid from "../../assets/covid_cover.webp";
import mobilemodal from "../../assets/mobilemodal.webp";
import enaModal from "../../assets/enaModal.webp";
import posModal from "../../assets/posModal.webp";
import cdlmodal from "../../assets/cdlmodal.webp";
import meditationModal from "../../assets/meditationModal.webp";

import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import { FiX } from "react-icons/fi";
import { FaShareSquare } from "react-icons/fa";
import { Element } from "react-scroll";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {},
  "& .MuiDialogActions-root": {},
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 3 }} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <FiX />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
const OurProject = () => {
  const BoxContainer1 = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 571px;
    background-color: #01497c;
    color: #fff;
    padding: 2px;
    background: #d9d9d9;
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(1, 73, 124, 0.45)
      ),
      url(${cdl});
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;

    background-position-x: 50%;
    background-position-y: 50%;
  `;

  const BoxContainer2 = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 321px;
    background-color: #01497c;
    color: #fff;
    cursor: pointer;

    background: #d9d9d9;
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(1, 73, 124, 0.45)
      ),
      url(${truck});
    background-repeat: no-repeat;
    background-size: cover;
  `;

  const BoxContainer3 = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 321px;
    background-color: #01497c;
    color: #fff;
    cursor: pointer;

    background: #d9d9d9;
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(1, 73, 124, 0.45)
      ),
      url(${medi});
    background-repeat: no-repeat;
    background-size: cover;
  `;

  const BoxContainer4 = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 240px;
    background-color: #01497c;
    color: #fff;
    cursor: pointer;

    background: #d9d9d9;
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(1, 73, 124, 0.45)
      ),
      url(${pos});
    background-repeat: no-repeat;
    background-size: cover;
    /* background-size: 100% 100%; */
  `;

  const BoxContainer5 = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 240px;
    background-color: #01497c;
    color: #fff;

    background: #d9d9d9;
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(1, 73, 124, 0.45)
      ),
      url(${pirny});
    background-position-x: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
  `;

  const BoxContainer6 = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 240px;
    background-color: #01497c;
    color: #fff;
    cursor: pointer;

    background: #d9d9d9;
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(1, 73, 124, 0.45)
      ),
      url(${covid});

    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
  `;

  const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `;
  const HeadingStyle = styled.p`
    font-size: 25pt;
    font-weight: 700;
    line-height: 78px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0px;
    @media only screen and (max-width: 768px) {
      font-size: 28px;
    }
    @media screen and (max-width: 644px) {
      font-size: 27px;
    }
    @media screen and (max-width: 400px) {
      text-align: center !important;
      font-size: 25px;
    }
  `;
  const ParagraphStyle = styled.p`
    font-size: 20pt;
    font-weight: 400;
    text-align: left;
    padding: 0px;
    margin: 0px;
    margin-bottom: 20px;
    @media only screen and (max-width: 768px) {
      font-size: 18px;
    }
    @media screen and (max-width: 644px) {
      font-size: 17px;
    }
    @media screen and (max-width: 400px) {
      text-align: center !important;
      font-size: 15px;
    }
  `;
  const BottomLeftText = styled.p`
    position: absolute;
    bottom: 2px;
    left: 2px;
    color: white;

    padding: 5px;
    margin-bottom: 2px;
    background-color: transparent;
    background-image: linear-gradient(315deg, transparent 0%, black 100%);
  `;
  const MainContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-inline: 10px;
  `;
  const MainBox = styled.div`
    display: flex;
    flex-direction: row;
    width: 85%;
    text-align: center;

    @media (max-width: 1100px) {
      flex-direction: column;
    }
  `;
  const Box1 = styled.div`
    position: relative;
    padding-top: 0px;
    flex: 35%;
  `;
  const Box2 = styled.div`
    padding: 2px;
    flex: 65%;
  `;
  const FlexContainer = styled.div`
    display: flex;
    flex-direction: row;

    text-align: center;
    @media (max-width: 1100px) {
      flex-direction: column;
    }
  `;
  const FlexLeft = styled.div`
    position: relative;
    padding: 2px;
    flex: 50%;
  `;
  const FlexRight = styled.div`
    position: relative;
    padding: 2px;
    flex: 50%;
  `;
  const FlexLeftOne = styled.div`
    position: relative;
    padding: 2px;
    flex: 51%;
  `;
  const FlexRightOne = styled.div`
    display: flex;

    flex-direction: row;
    flex: 49%;
    padding: 2px;
    @media (max-width: 1100px) {
      flex-direction: row;
    }
  `;
  const RightOne = styled.div`
    position: relative;
    padding: 0px;
    flex: 50%;
  `;
  const RightTwo = styled.div`
    position: relative;
    padding: 2px;
    flex: 51%;
  `;

  const DialogTitle = styled.p`
    font-style: normal;
    font-weight: 700;
    font-size: 25pt;
    line-height: 69px;
    color: #01497c;
  `;
  const DialogPara = styled.p`
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 35px;
    text-align: justify;
   padding: 0px 5px;
    color: #000000;
  `;

  const [openDriverApp, setOpenDriveApp] = useState(false);
  const [openPOS, setOpenPOS] = useState(false);
  const [openMeditation, setOpenMeditation] = useState(false);
  const [openPhotographyApp, setOpenPhotographyApp] = useState(false);
  const [openCarpool, setOpenCarpool] = useState(false);
  const [openENA, setOpenENA] = useState(false);

  const handleOpenDriveApp = () => {
    setOpenDriveApp(true);
  };
  const handleCloseDriveApp = () => {
    setOpenDriveApp(false);
  };
  const handleOpenPOS = () => {
    setOpenPOS(true);
  };
  const handleClosePOS = () => {
    setOpenPOS(false);
  };
  const handleOpenMeditation = () => {
    setOpenMeditation(true);
  };
  const handleCloseMeditation = () => {
    setOpenMeditation(false);
  };
  const handleOpenPhotographyApp = () => {
    setOpenPhotographyApp(true);
  };
  const handleClosePhotographyApp = () => {
    setOpenPhotographyApp(false);
  };
  const handleOpenCarpool = () => {
    setOpenCarpool(true);
  };
  const handleCloseCarpool = () => {
    setOpenCarpool(false);
  };

  const handleOpenENA = () => {
    setOpenENA(true);
  };
  const handleCloseENA = () => {
    setOpenENA(false);
  };

  return (
    <>
      <Element
        name="ourProjects"
        style={{ width: "100%", paddingTop: "7vh" }}
        id="portfolio"
      >
        <BootstrapDialog
          maxWidth="lg"
          fullScreen
          onClose={handleCloseDriveApp}
          aria-labelledby="customized-dialog-title"
          open={openDriverApp}
          BackdropProps={{ invisible: false }}
        >
          <DialogContent dividers className="modal-transparency">
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleCloseDriveApp}
            />

            <Grid
              container
              spacing={2}
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={5}>
                <img
                  src={mobilemodal}
                  style={{
                    objectFit: "cover",
                    minWidth: "85%",
                    maxWidth: "90%",
                    alt: "mobilemodal",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={7}>
                <DialogTitle>Truck Driver </DialogTitle>
                <DialogPara>
                  Driving Success with CDL1000's Driver Tracking App!
                  Introducing a hybrid application for CDL1000 that streamlines
                  the shipment process for both shippers and drivers. Shipping
                  companies can now book, manage, and track shipments in
                  real-time. Drivers can easily plan loads, receive payments,
                  and submit documents with ease, keeping everything organized
                  and efficient. It's a win-win situation, as both shippers and
                  drivers benefit from a seamless and streamlined experience.
                </DialogPara>
                <div style={{ marginInline: "6px" }}>
                  <button
                    style={{
                      padding: "2px 10px",
                      margin: "2px",
                      border: "2px solid black",
                      borderRadius: 4,
                      backgroundColor: "transparent",
                    }}
                  >
                    Node Js
                  </button>
                  <button
                    style={{
                      padding: "2px 10px",
                      border: "2px solid black",
                      margin: "2px",
                      borderRadius: 4,
                      backgroundColor: "transparent",
                    }}
                  >
                    Express Js
                  </button>
                  <button
                    style={{
                      padding: "2px 10px",
                      border: "2px solid black",
                      margin: "2px",
                      borderRadius: 4,
                      backgroundColor: "transparent",
                    }}
                  >
                    React Js
                  </button>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        </BootstrapDialog>
        <BootstrapDialog
          maxWidth="lg"
          fullScreen
          onClose={handleClosePOS}
          aria-labelledby="customized-dialog-title"
          open={openPOS}
          BackdropProps={{ invisible: false }}
        >
          <DialogContent dividers className="modal-transparency">
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClosePOS}
            />

            <Grid
              container
              spacing={2}
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={5}>
                <img
                  src={posModal}
                  style={{
                    objectFit: "cover",
                    minWidth: "85%",
                    maxWidth: "90%",
                    alt: "posModal",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={7} ju>
                <DialogTitle>POINT OF SALES </DialogTitle>
                <DialogPara>
                  Unleash the power of streamlined commerce with our
                  cutting-edge cloud-based Point of Sale system. Effortlessly
                  manage inventory, process daily credit/debit transactions,
                  collect valuable customer data and seamlessly integrate with
                  your accounting software for a complete financial management
                  solution for your biz.
                </DialogPara>

                <div style={{ marginTop: "10vh" }}>
                  <button
                    style={{
                      padding: "2px 10px",
                      border: "2px solid black",
                      borderRadius: 4,
                      backgroundColor: "transparent",
                      paddingInline: "6px",
                    }}
                  >
                    Node Js
                  </button>
                  <button
                    style={{
                      padding: "2px 10px",
                      marginInline: "20px",
                      border: "2px solid black",
                      borderRadius: 4,
                      backgroundColor: "transparent",
                    }}
                  >
                    Express Js
                  </button>
                  <button
                    style={{
                      padding: "2px 10px",
                      border: "2px solid black",
                      borderRadius: 4,
                      backgroundColor: "transparent",
                    }}
                  >
                    React Js
                  </button>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        </BootstrapDialog>
        <BootstrapDialog
          maxWidth="lg"
          fullScreen
          onClose={handleCloseMeditation}
          aria-labelledby="customized-dialog-title"
          open={openMeditation}
          BackdropProps={{ invisible: false }}
        >
          <DialogContent dividers className="modal-transparency">
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleCloseMeditation}
            />

            <Grid
              container
              spacing={2}
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={5}>
                <img
                  src={meditationModal}
                  style={{
                    objectFit: "cover",
                    minWidth: "85%",
                    maxWidth: "90%",
                    alt: "meditationModal",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={7} ju>
                <DialogTitle>Meditation </DialogTitle>
                <DialogPara>
                  Get ready to level up your mindfulness game with our
                  astrology-powered app. Schedule a virtual session with a
                  licensed astrologer and get a personalized plan for your week,
                  expert advice, and strategies for managing mental health. And
                  if you need a moment of zen, dive into our collection of
                  guided meditations and conquer life's obstacles like a boss.
                  Say goodbye to stress and hello to a calmer, more focused you
                  with our Meditation app!
                </DialogPara>
                <div style={{ marginTop: "3vh" }}>
                  <button
                    style={{
                      padding: "2px 10px",
                      border: "2px solid black",
                      borderRadius: 4,
                      backgroundColor: "transparent",
                      paddingInline: "6px",
                    }}
                  >
                    Flutter
                  </button>
                  <button
                    style={{
                      padding: "2px 10px",
                      marginInline: "20px",
                      border: "2px solid black",
                      borderRadius: 4,
                      backgroundColor: "transparent",
                    }}
                  >
                    Firebase
                  </button>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        </BootstrapDialog>
        <BootstrapDialog
          maxWidth="lg"
          fullScreen
          onClose={handleCloseCarpool}
          aria-labelledby="customized-dialog-title"
          open={openCarpool}
          BackdropProps={{ invisible: false }}
        >
          <DialogContent dividers className="modal-transparency">
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleCloseCarpool}
              
            />

            <Grid
              container
              spacing={2}
              justifyContent="space-evenly"
              alignItems="center"
              style={{height:"80vh"}}
            >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={5}>
                <img
                  src={cdlmodal}
                  style={{
                    objectFit: "cover",
                    minWidth: "85%",
                    maxWidth: "90%",
                    alt: "cdlmodal",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={7}>
                <DialogTitle>CDL1000 </DialogTitle>
                <DialogPara>
                  CDL's Tech Transformation: Leveling Up Their Game! Our team
                  took CDL's website to new heights by migrating it from
                  WordPress to a cutting-edge tech stack, including Next JS and
                  Go Lang as the primary backend. We didn't just give their site
                  a facelift, we built it from scratch with advanced features
                  like Lazy Loading, Firebase events, and optimized environments
                  to track user journey and deliver a lightning-fast experience.
                  But wait, there's more! We also manage their entire technology
                  portfolio, revolutionized their quoting process with
                  custom-built engines, and turbocharged their performance by
                  moving their backend from Python to Go Lang, resulting in a
                  tenfold increase in speed. CDL's tech game just leveled up!
                </DialogPara>
                <DialogPara>
                  <a
                    target="_blank"
                    href={"https://www.cdl1000.com/"}
                    rel="noopener noreferrer"
                    style={{ color: "black" }}
                  >
                    <FaShareSquare size={24} />
                  </a>
                </DialogPara>
                <div>
                  <button
                    style={{
                      padding: "2px 10px",
                      border: "2px solid black",
                      borderRadius: 4,
                      backgroundColor: "transparent",
                      paddingInline: "6px",
                    }}
                  >
                    Next Js
                  </button>
                  <button
                    style={{
                      padding: "2px 10px",
                      marginInline: "20px",
                      border: "2px solid black",
                      borderRadius: 4,
                      backgroundColor: "transparent",
                    }}
                  >
                    GoLang
                  </button>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        </BootstrapDialog>
        <BootstrapDialog
          maxWidth="lg"
          fullScreen
          onClose={handleClosePhotographyApp}
          aria-labelledby="customized-dialog-title"
          open={openPhotographyApp}
          BackdropProps={{ invisible: false }}
        >
          <DialogContent dividers className="modal-transparency">
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClosePhotographyApp}
            />

            <Grid
              container
              spacing={2}
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={5}>
                <img
                  src={pinryModal}
                  style={{
                    objectFit: "cover",
                    minWidth: "85%",
                    maxWidth: "90%",
                    alt: "nofapmodal",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={7} ju>
                <DialogTitle>Pinry Photography </DialogTitle>
                <DialogPara>
                  A cutting-edge app that allows users to enhance their photos
                  with location-based data, enabling fellow users to discover
                  new travel destinations and more!
                </DialogPara>

                <div style={{ marginTop: "10vh" }}>
                  <button
                    style={{
                      padding: "2px 10px",
                      border: "2px solid black",
                      borderRadius: 4,
                      backgroundColor: "transparent",
                      paddingInline: "6px",
                    }}
                  >
                    Node Js
                  </button>
                  <button
                    style={{
                      padding: "2px 10px",
                      marginInline: "20px",
                      border: "2px solid black",
                      borderRadius: 4,
                      backgroundColor: "transparent",
                    }}
                  >
                    Express Js
                  </button>
                  <button
                    style={{
                      padding: "2px 10px",
                      border: "2px solid black",
                      borderRadius: 4,
                      backgroundColor: "transparent",
                    }}
                  >
                    React Native
                  </button>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        </BootstrapDialog>
        <BootstrapDialog
          maxWidth="lg"
          fullScreen
          onClose={handleCloseENA}
          aria-labelledby="customized-dialog-title"
          open={openENA}
          BackdropProps={{ invisible: false }}
        >
          <DialogContent dividers className="modal-transparency">
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleCloseENA}
            />

            <Grid
              container
              spacing={2}
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={5}>
                <img
                  src={enaModal}
                  style={{
                    objectFit: "cover",
                    minWidth: "85%",
                    maxWidth: "90%",
                    alt: "enaModal",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={7} ju>
                <DialogTitle>ENA </DialogTitle>
                <DialogPara>
                  "Get ahead of the pandemic with ENA, your ultimate digital
                  sidekick in the battle against Covid-19. Log your symptoms,
                  get real-time probability assessments, and access a wealth of
                  educational podcasts from top healthcare experts - all in one
                  comprehensive and user-friendly platform"
                </DialogPara>
                <div style={{ marginTop: "10vh" }}>
                  <button
                    style={{
                      padding: "2px 10px",
                      border: "2px solid black",
                      borderRadius: 4,
                      backgroundColor: "transparent",
                      paddingInline: "6px",
                    }}
                  >
                    React Native
                  </button>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        </BootstrapDialog>

        <div style={{ width: "100%" }}>
          <MainContainer>
            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                justifyContent: "center",
                width: "85%",
                marginTop: "0rem",
              }}
            >
              <Grid item lg={12}>
                <HeadingStyle>Our Projects</HeadingStyle>
              </Grid>
            </Grid>
          </MainContainer>

          <MainContainerBox>
            <MainBox>
              <Box1>
                <BoxContainer1 onClick={handleOpenCarpool}></BoxContainer1>
                <BottomLeftText> CDL1000</BottomLeftText>
              </Box1>
              <Box2>
                <FlexContainer>
                  <FlexLeft>
                    <BoxContainer2 onClick={handleOpenDriveApp}></BoxContainer2>
                    <BottomLeftText>Truck Driver </BottomLeftText>
                  </FlexLeft>
                  <FlexRight>
                    <BoxContainer3
                      onClick={handleOpenMeditation}
                    ></BoxContainer3>
                    <BottomLeftText>Meditation </BottomLeftText>
                  </FlexRight>
                </FlexContainer>

                <FlexContainer>
                  <FlexLeftOne>
                    <BoxContainer4 onClick={handleOpenPOS}></BoxContainer4>
                    <BottomLeftText>Point of Sales </BottomLeftText>
                  </FlexLeftOne>
                  <FlexRightOne>
                    <RightOne>
                      <BoxContainer5
                        onClick={handleOpenPhotographyApp}
                      ></BoxContainer5>
                      <BottomLeftText>Pinry </BottomLeftText>
                    </RightOne>
                    <RightTwo>
                      <BoxContainer6 onClick={handleOpenENA}></BoxContainer6>
                      <BottomLeftText>ENA Covid 19</BottomLeftText>
                    </RightTwo>
                  </FlexRightOne>
                </FlexContainer>
              </Box2>
            </MainBox>
          </MainContainerBox>
        </div>
      </Element>
    </>
  );
};

export default OurProject;
