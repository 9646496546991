import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { FiMenu } from "react-icons/fi";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-scroll";
import styled from "styled-components";

import ccLogoCropp from "../../assets/CCLogoCrop.png";
import navlogo from '../../assets/navlogo.png';
const Nav = styled.div`
  position: sticky;
  background: rgb(236, 251, 251);  top: 0px;
  width: 100%;
  z-index: 3;
  padding: 0px;
  margin: 0px;
  @media (min-width: 1200px) {
    display: flex;
    justify-content: center;
  }
`;

const Drawer = styled.div`
  @media (min-width: 771px) {
    display: none;
  }
`;
const Li = styled.li`
  @media (max-width: 770px) {
    font-size: 1rem;
    margin-block: 1rem;
    display: flex;
    margin-left: -2rem;
  }
  @media (max-width: 840px) {
    margin-left: 6px;
  }
  position: relative;
  display: inline-block;
  margin-left: 40px;
  text-decoration: none !important;
  padding: 0px 0;
  color: #76787b;
  cursor: pointer;
  font-weight: 600;
  transition: 0.7s ease !important;
  padding: 20px;
  padding-bottom: 0px;

  &:hover {
    color: #01497c;
    background-color:  rgb(236, 251, 251);
    color: #01497c;
   
      transition: 0.7s ease !important;
    

  }
  @media (max-width: 954px) {
    margin-left: 5px;
  }
`;

const LogoImg = styled.img`
  height: 50px;
  ${(props) => (props) =>
    props.small ? null : `  animation: fadeInDown 1800ms ;`}
  @keyframes fadeInDown {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;
const LogoImg1 = styled.img`
  height: 50px;
  ${(props) => (props) =>
    props.small ? `  animation: fadeInDown 1800ms ;` : null}

  @keyframes fadeInDown {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;


const NewNav=styled.div`
 position: sticky;
  background: rgb(236, 251, 251);
  top: 0px;
  width: 100%;
  z-index: 3;
  padding: 0px;
  margin: 0px;

  @media (max-width: 770px) {
    display: none;
  }

`;
const Too=styled.div`
 @media (min-width: 800px) {
    display: none;
    font-size:12px;
  }
`
const TabItem=styled.div`
display:flex;
&:hover{
  color:#01497c ;
  text-underline-offset: 6px;
    text-decoration: underline  #01497c 3px   !important;
    -webkit-text-decoration-color:#01497c;
  text-decoration-color: #01497c; 
  animation: fadeIn 2s;
}

@keyframes fadeIn {
  0% { opacity: 0.2; }
  100% { opacity: 1; }
}
`;
export const Navbar = () => {
  const [showHomeNo, setShowHomeNo] = useState(false);
  const [showServiceNo, setServiceNo] = useState(false);
  const [showTeamNo, setTeamNo] = useState(false);
  const [showPortfolioNo, setPortfolioNo] = useState(false);
  const [showContactUsNo, setContactUsNo] = useState(false);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [show, setShow] = useState(false);
  const [small, setSmall] = useState(false);
  const [scroll, setScroll] = useState(0);
  const onScroll = () => {
    const Scrolled = document.documentElement.scrollTop;
    const MaxHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const ScrollPercent = (Scrolled / MaxHeight) * 100;
    setScroll(ScrollPercent);
  };
  if (typeof window !== "undefined") {
  window.addEventListener("scroll", onScroll);
  }


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setSmall(window.pageYOffset > 100 )
      );
    }
  });

  return (

    <>
<NewNav  >
    <div style={{display:'flex',flexDirection:'row'}}>
        <div style={{marginLeft:'5%',display:'flex',alignItems:'center',paddingLeft:'2px'}} >
          {scroll <= 2 ? 
        small ? (
                <LogoImg
                  small={small}
                  src={navlogo}
                />
              ) : (
                <LogoImg
                  small={small}
                  src={navlogo}
                />
              )
              : small ? (
                <LogoImg
                  small={small}
                  src={ccLogoCropp}
                />
              ) : (
                <LogoImg
                  small={small}
                  src={ccLogoCropp}
                />
              )}
        </div>
        <div style={{width:'100%',marginRight:'9% '}}>
          <div style={{display:'flex',justifyContent:'center'}}>
        <ul >
              <Li
                onMouseEnter={() => setShowHomeNo(true)}
                onMouseLeave={() => setShowHomeNo(false)}
                hash="#home"
                style={{marginLeft:'19px'}}
              >
                <Link activeClass="activeNavbarItem" spy={true} to="home" style={{fontWeight:'400',color:'#6d7e77'}}>
                  <TabItem >
                    {showHomeNo == true ? "" : ""}
                    <div style={{ color: "white" }}>
                      {showHomeNo == false ? "" : ""}
                    </div>
                    {" Home"}
                  </TabItem>
                </Link>
              </Li>

              <Li
                onMouseEnter={() => setServiceNo(true)}
                onMouseLeave={() => setServiceNo(false)}
                hash="#service"
              >
                <Link activeClass="activeNavbarItem" spy={true} to="service" style={{fontWeight:'400',color:'#6d7e77'}}>
                  <TabItem>
                    {showServiceNo == true ? "" : ""}
                    <div style={{ color: "white" }}>
                      {showServiceNo == false ? "" : ""}
                    </div>
                    {"Services"}
                  </TabItem>
                </Link>
              </Li>
            
              <Li
                onMouseEnter={() => setPortfolioNo(true)}
                onMouseLeave={() => setPortfolioNo(false)}
                hash="#portfolio"
              >
                <Link activeClass="activeNavbarItem" spy={true} to="portfolio" style={{fontWeight:'400',color:'#6d7e77'}}>
                  <TabItem>
                    {showPortfolioNo == true ? "" : ""}
                    <div style={{ color: "white" }}>
                      {showPortfolioNo == false ? "" : ""}
                    </div>
                    {"Portfolio"}
                  </TabItem>
                </Link>
              </Li>
              <Li
                onMouseEnter={() => setTeamNo(true)}
                onMouseLeave={() => setTeamNo(false)}
                hash="#team"
              >
                <Link activeClass="activeNavbarItem" spy={true} to="team" style={{fontWeight:'400',color:'#6d7e77'}}>
                  <TabItem>
                    {showTeamNo == true ? "" : ""}
                    <div style={{ color: "white" }}>
                      {showTeamNo == false ? "" : ""}
                    </div>
                    {"Team"}
                  </TabItem>
                </Link>
              </Li>
              <Li
                onMouseEnter={() => setContactUsNo(true)}
                onMouseLeave={() => setContactUsNo(false)}
                hash="#contact"
              >
                <Link activeClass="activeNavbarItem" spy={true} to="contact" style={{fontWeight:'400',color:'#6d7e77'}}>
                  <TabItem>
                    {showContactUsNo == true ? "" : ""}
                    <div style={{ color: "white" }}>
                      {showContactUsNo == false ? "" : ""}
                    </div>
                    {"Contact Us"}
                  </TabItem>
                </Link>
              </Li>
            </ul>

            </div>
          </div>
          <Too>
          <div style={{display:'flex',alignItems:'center',paddingRight:'2px'}}>
        {small ? (
                <LogoImg1
                  small={small}
                  src={'https://upload.wikimedia.org/wikipedia/commons/8/89/HD_transparent_picture.png'}
                />
              ) : (
                <LogoImg
                  small={small}
                  src={'https://upload.wikimedia.org/wikipedia/commons/8/89/HD_transparent_picture.png'}
                />
              )}
        </div>
        </Too>
    </div>
</NewNav>




    <Nav  >
    
        <Container>
        <Drawer >
          <FiMenu size={38} variant="primary" onClick={handleShow}>
            Launch
          </FiMenu>
          <Offcanvas show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton style={{ marginRight: "1rem" }}>
              <Offcanvas.Title></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <ul style={{ paddingLeft: "0px" }}>
                <Li hash="#home">
                  <Link
                    activeClass="activeNavbarItem"
                    spy={true}
                    to="home"
                    onClick={() => setShow(false)}
                  >
                    Home
                  </Link>
                </Li>

                <Li hash="#service">
                  <Link
                    activeClass="activeNavbarItem"
                    spy={true}
                    to="service"
                    onClick={() => setShow(false)}
                  >
                    Services{" "}
                  </Link>
                </Li>
                <Li hash="#team">
                  <Link
                    activeClass="activeNavbarItem"
                    spy={true}
                    to="team"
                    onClick={() => setShow(false)}
                  >
                    Team{" "}
                  </Link>
                </Li>
                <Li hash="#portfolio">
                  <Link
                    activeClass="activeNavbarItem"
                    spy={true}
                    to="portfolio"
                    onClick={() => setShow(false)}
                  >
                    Portfolio{" "}
                  </Link>
                </Li>
                <Li hash="#contact">
                  <Link
                    activeClass="activeNavbarItem"
                    spy={true}
                    to="contact"
                    onClick={() => setShow(false)}
                  >
                    Contact Us{" "}
                  </Link>
                </Li>
              </ul>
            </Offcanvas.Body>
          </Offcanvas>
        </Drawer>
      </Container>
    </Nav>
    </>
  );
};
