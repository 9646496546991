import React from "react";

import { Grid } from "@mui/material";
import styled from "styled-components";
import { BsGithub } from "react-icons/bs";

const OtherProject = () => {
  const BoxContainer = styled.div`
    @media only screen and (max-width: 768px) {
    }
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 50%; */
    /* min-height: 200px; */
    color: #fff;
    background-color: rgb(1, 73, 124);
    margin-top: 10px;
    margin-right: 10px;
    padding: 1rem;
    height: 300px;
    /* box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); */
    width: 99%;
    @media only screen and (max-width: 1082px) {
      height: 270px;
    }
    @media only screen and (max-width: 900px) {
      height: 270px;
    }
  `;
  const BoxContainerTransparent = styled.div`
    @media only screen and (max-width: 768px) {
    }
    /* box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); */
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 50%; */
    /* min-height: 200px; */
    color: black;
    margin-top: 10px;
    background-color: #f2f2f2;
    height: 300px;
    width: 99%;
    padding: 1rem;
    @media only screen and (max-width: 1082px) {
      height: 270px;
    }
    @media only screen and (max-width: 900px) {
      height: 270px;
    }
  `;

  const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `;
  const HeadingStyle = styled.p`
    font-size: 25pt;
    font-weight: 700;
    line-height: 78px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0px;
    margin-top: 4vh;
    @media only screen and (max-width: 768px) {
      font-size: 28px;
    }
    @media screen and (max-width: 644px) {
      font-size: 27px;
    }
    @media screen and (max-width: 400px) {
      text-align: center !important;
      font-size: 25px;
    }
  `;

  const SubHeadingStyle = styled.p`
    font-size: 24px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 0px;
  `;
  const SubParagraphStyle = styled.p`
    font-size: 20pt;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    /* padding: 0px;
    margin: 0px;
    margin-bottom: 20px; */
    @media only screen and (max-width: 768px) {
      font-size: 18px;
    }
    @media screen and (max-width: 644px) {
      font-size: 17px;
    }
    @media screen and (max-width: 400px) {
      text-align: center !important;

      font-size: 15px;
    }
  `;
  const ParagraphStyle = styled.p`
    font-size: 16px;
    font-weight: 400;
    text-align: left;
  `;
  const Div = styled.div`
    /* margin-top: 3rem; */
  `;

  return (
    <Div style={{ width: "100%" }}>
      <MainContainer>
        <Grid
          container
          spacing={2}
          style={{
            display: "flex",
            justifyContent: "center",
            width: "86%",
          }}
        >
          <Grid item lg={12}>
            <HeadingStyle>Other Projects</HeadingStyle>
          </Grid>
        </Grid>
      </MainContainer>

      <MainContainer>
        <Grid
          style={{
            width: "86%",
          }}
          justifyContent="center"
          alignItems="center"
          container
          spacing={1}
        >
          <Grid item xs={12} md={4}>
            <BoxContainer>
              <Grid>
                <SubHeadingStyle>Flutter Facebook SDK</SubHeadingStyle>
                <br />
                <ParagraphStyle style={{ color: "#52a8f2" }}>
                  Flutter | Android | IOS
                </ParagraphStyle>
                <ParagraphStyle>
                  Flutter plugin to implement Facebook deep links and app events
                </ParagraphStyle>
                <a
                  target="_blank"
                  href="https://pub.dev/packages/flutter_facebook_sdk"
                  rel="noopener noreferrer"
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <BsGithub size={22} color="black" />
                  </div>
                </a>
              </Grid>
            </BoxContainer>
          </Grid>
          <Grid item xs={12} md={3}>
            <BoxContainerTransparent>
              <Grid>
                <SubHeadingStyle> Crypto market Data</SubHeadingStyle> <br />
                <ParagraphStyle style={{ color: "#52a8f2" }}>
                  {" "}
                  ReactJS | Javascript | NodeJS
                </ParagraphStyle>
                <ParagraphStyle>
                  Landing page to visualize and gather insisghts on different
                  crypto currencies
                </ParagraphStyle>
                <a
                  target="_blank"
                  href="https://objective-lalande-edc5c9.netlify.app"
                  rel="noopener noreferrer"
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <BsGithub size={22} color="black" />
                  </div>
                </a>
              </Grid>
            </BoxContainerTransparent>
          </Grid>

          <Grid item xs={12} md={5}>
            <BoxContainerTransparent>
              <Grid item>
                <SubHeadingStyle>Reddit Data Scrapper</SubHeadingStyle> <br />
                <ParagraphStyle style={{ color: "#52a8f2" }}>
                  Python | Selenium | Beautiful Soup
                </ParagraphStyle>
                <ParagraphStyle>
                  Reddit posts data scrapper that can be saved into SQLite
                </ParagraphStyle>
                <a
                  target="_blank"
                  href="https://github.com/saadfarhan124/Reddit-Data-Scrapper"
                  rel="noopener noreferrer"
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <BsGithub size={22} color="black" />
                  </div>
                </a>
              </Grid>
            </BoxContainerTransparent>
          </Grid>
        </Grid>

        <Grid
          justifyContent="center"
          alignItems="center"
          container
          spacing={1}
          style={{
            width: "86%",
          }}
        >
          <Grid item xs={12} md={5}>
            <BoxContainerTransparent>
              <Grid>
                <SubHeadingStyle>Alumni Portal Web</SubHeadingStyle> <br />
                <ParagraphStyle style={{ color: "#52a8f2" }}>
                  {" "}
                  C# | Javascript | ASP.Net
                </ParagraphStyle>
                <ParagraphStyle>
                  Platform to keep track of Alumnis and their achivements
                </ParagraphStyle>
                <a
                  target="_blank"
                  href="https://github.com/saadfarhan124/Alumni-Portal"
                  rel="noopener noreferrer"
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <BsGithub size={22} color="black" />
                  </div>
                </a>
              </Grid>
            </BoxContainerTransparent>
          </Grid>
          <Grid item xs={12} md={3}>
            <BoxContainer>
              <Grid>
                <SubHeadingStyle>Spark Admin Panel</SubHeadingStyle> <br />
                <ParagraphStyle style={{ color: "#52a8f2" }}>
                  ReactJS | javascript | NodeJS
                </ParagraphStyle>
                <ParagraphStyle>
                  Created generic and ready to hook admin template
                </ParagraphStyle>
                <a
                  target="_blank"
                  href="https://musing-thompson-ee46fb.netlify.app"
                  rel="noopener noreferrer"
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <BsGithub size={22} color="black" />
                  </div>
                </a>
              </Grid>
            </BoxContainer>
          </Grid>
          <Grid item xs={12} md={4}>
            <BoxContainerTransparent>
              <Grid>
                <SubHeadingStyle> HereMaps npm package </SubHeadingStyle> <br />
                <ParagraphStyle style={{ color: "#52a8f2" }}>
                  ReactJS | heremap | NodeJS
                </ParagraphStyle>
                <ParagraphStyle>
                  React plugin to calculate trucking routes which can be
                  displayed on Google Maps
                </ParagraphStyle>
                <a
                  target="_blank"
                  href="https://www.npmjs.com/package/heremap-polyline-decoder"
                  rel="noopener noreferrer"
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <BsGithub size={22} color="black" />
                  </div>
                </a>
              </Grid>
            </BoxContainerTransparent>
          </Grid>
        </Grid>
      </MainContainer>
    </Div>
  );
};

export default OtherProject;
