import logo from './logo.svg';
import './App.css';
import { Home } from './page/Home/home';
import { useEffect } from 'react';
function App() {

  return (
      <Home/>
  );
}

export default App;
