import React from "react";
import "../../page/Home/style.css";
import { Grid } from "@mui/material";
import styled from "styled-components";
import teampic from "../../assets/team.webp";
import farhan from "../../assets/Farhan-min.webp";
import anwar from "../../assets/Anwar.webp";
import aebad from "../../assets/Aebad-min.webp";
import akber from "../../assets/akber-min.webp";
import yasoob from "../../assets/yasoob.webp";
import wahaj from "../../assets/wahaj-min.webp";
import tooba from "../../assets/tooba-min.webp";
import { BsGithub, BsBehance, BsLinkedin } from "react-icons/bs";
import { FaArtstation } from "react-icons/fa";
import abubakar from "../../assets/abubabakar.webp";
import hassam from "../../assets/hassam.webp";

const MeetClan = () => {
  const teamData = [
    {
      name: "Saad Farhan",
      img: "https://media-exp1.licdn.com/dms/image/C5603AQGSc4XbL91Rkg/profile-displayphoto-shrink_800_800/0/1636041469649?e=1655337600&v=beta&t=r1AwlpTy_0rmbFkUF76XVdQXlC2fnyP4oqurZ09bC4w",
      des: "Team Lead",
      github: "https://github.com/saadfarhan124",
      linked: "https://www.linkedin.com/in/saadfarhan124/",
    },
    {
      name: "Saad Anwar",
      img: "https://media-exp1.licdn.com/dms/image/C4D03AQH5z3KdpTN8kQ/profile-displayphoto-shrink_800_800/0/1516959195744?e=1655337600&v=beta&t=6AMarVtcnJkT94OX7CzB4pGkeJ5Uc1DG-0NngBF3Q-4",
      des: "Team Lead",
      linked: "https://www.linkedin.com/in/saad-anwar16/",
      github: "https://github.com/5aad",
    },
    {
      name: "Saad Hassam",
      img: "https://media-exp1.licdn.com/dms/image/C4E03AQEQLvwQH6bsmQ/profile-displayphoto-shrink_800_800/0/1646918353473?e=1655337600&v=beta&t=xkWxBvNT5Q4byfyBQqKVPM9ycZxlZXToXw8ZpqMMakA",
      des: "Creative Lead",
      linked: "https://www.linkedin.com/in/saad-hassam-b6950b70/",
      github: "https://www.artstation.com/hassamart",
    },
    {
      name: "Abubakar Ijaz",
      img: "https://media-exp1.licdn.com/dms/image/C4D03AQE2etvYEJhc8w/profile-displayphoto-shrink_800_800/0/1643873032471?e=1655337600&v=beta&t=iCq_kgOxgxyMXhqWtg6G4btB19TW6WNDQYtYjmMk-54",
      des: "SQA",
      linked: "https://www.linkedin.com/in/abubakarijaz",
      github: "https://github.com/abubakar559",
    },
    {
      name: "Akber Taj",
      img: "/static/akbar.png",
      des: "UI/UX",
      linked: "https://www.linkedin.com/in/akbertaj",
      github: "https://www.Behance.net/akbertaj1",
    },
    {
      name: "Aebad Ul Quadisr",
      img: "../../assets/Aebad.JPG",
      des: "Software Engineer",
      linked: "https://www.linkedin.com/in/aebad-ul-quadir-2b7b74203/",
      github: "https://github.com/AebadUQ",
    },
    {
      name: "Wahaj",
      img: "https://media-exp1.licdn.com/dms/image/C4E03AQHPK3Bn9SXrkQ/profile-displayphoto-shrink_800_800/0/1636827602972?e=1658966400&v=beta&t=35nxOCWPDiOs4Ss4xKtUIYUe2iDX1D70TfLpu0pjm1I",
      des: "Software Engineer",
      linked: "https://www.linkedin.com/in/wahaj-rashid-0a098a214/",
      github: "https://github.com/AebadUQ",
    },
    {
      name: "Syed Yasoob",
      img: "https://media-exp1.licdn.com/dms/image/C4E03AQHPK3Bn9SXrkQ/profile-displayphoto-shrink_800_800/0/1636827602972?e=1658966400&v=beta&t=35nxOCWPDiOs4Ss4xKtUIYUe2iDX1D70TfLpu0pjm1I",
      des: "Software Engineer",
      linked: "https://www.linkedin.com/in/aebad-ul-quadir-2b7b74203/",
      github: "https://github.com/AebadUQ",
    },
    {
      name: "Jawed Iqbal",
      img: "https://media-exp1.licdn.com/dms/image/C4E03AQHPK3Bn9SXrkQ/profile-displayphoto-shrink_800_800/0/1636827602972?e=1658966400&v=beta&t=35nxOCWPDiOs4Ss4xKtUIYUe2iDX1D70TfLpu0pjm1I",
      des: "Software Engineer",
      linked: "https://www.linkedin.com/in/aebad-ul-quadir-2b7b74203/",
      github: "https://github.com/AebadUQ",
    },
  ];

  const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `;
  const HeadingStyle = styled.p`
    font-size: 25pt;
    font-weight: 700;
    line-height: 78px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0px;
    /* margin-top: 5rem; */
    @media only screen and (max-width: 768px) {
      font-size: 28px;
    }
    @media screen and (max-width: 644px) {
      font-size: 27px;
    }
    @media screen and (max-width: 400px) {
      text-align: center !important;

      font-size: 25px;
    }
  `;
  const ParagraphStyle = styled.p`
    font-size: 20pt;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    padding: 0px;
    margin: 0px;
    margin-bottom: 20px;
    @media only screen and (max-width: 768px) {
      font-size: 18px;
    }
    @media screen and (max-width: 644px) {
      font-size: 17px;
    }
    @media screen and (max-width: 400px) {
      font-size: 15px;
    }
  `;
  const ImageContainerOne = styled.div`
    @media only screen and (max-width: 768px) {
      height: 400px;
      // width: 250px;
      background-size: cover;
    }
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    height: 100%;

    filter: grayscale(100%);
    transition: 1s;
    background-image: url(${farhan});
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 50%;
    .test {
      display: none;
    }
    &:hover {
      .test {
        display: block;
        color: white;
        position: absolute;
        text-align: center;
      }
      filter: grayscale(0%);
      position: relative;
      cursor: pointer;

      background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0),
          #01497c
        ),
        url(${farhan});
      background-repeat: no-repeat;
      background-size: cover;
    }
  `;

  const ImageContainerAebad = styled.div`
    @media only screen and (max-width: 768px) {
      height: 400px;
      background-size: cover;
    }
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
    transition: 1s;
    background-image: url(${aebad});
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 50%;
    .test {
      display: none;
    }
    &:hover {
      .test {
        display: block;
        color: white;
        position: absolute;
        text-align: center;
      }
      filter: grayscale(0%);
      position: relative;
      cursor: pointer;

      background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0),
          #01497c
        ),
        url(${aebad});
      background-repeat: no-repeat;
      background-size: cover;
    }
  `;
  const ImageContainerWahaj = styled.div`
    @media only screen and (max-width: 768px) {
      height: 400px;
      background-size: cover;
    }
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
    transition: 1s;
    background-image: url(${wahaj});
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 50%;
    .test {
      display: none;
    }
    &:hover {
      .test {
        display: block;
        color: white;
        position: absolute;
        text-align: center;
      }
      filter: grayscale(0%);
      position: relative;
      cursor: pointer;

      background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0),
          #01497c
        ),
        url(${wahaj});
      background-repeat: no-repeat;
      background-size: cover;
    }
  `;
  const ImageContainerAbubakar = styled.div`
    @media only screen and (max-width: 768px) {
      height: 400px;
      background-size: cover;
    }
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
    transition: 1s;
    background-image: url(${abubakar});
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 50%;
    .test {
      display: none;
    }
    &:hover {
      .test {
        display: block;
        color: white;
        position: absolute;
        text-align: center;
      }
      filter: grayscale(0%);
      position: relative;
      cursor: pointer;

      background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0),
          #01497c
        ),
        url(${abubakar});
      background-repeat: no-repeat;
      background-size: cover;
    }
  `;
  const ImageContainerTooba = styled.div`
    @media only screen and (max-width: 768px) {
      height: 400px;
      background-size: cover;
    }
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
    transition: 1s;
    background-image: url(${tooba});
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 50%;
    .test {
      display: none;
    }
    &:hover {
      .test {
        display: block;
        color: white;
        position: absolute;
        text-align: center;
      }
      filter: grayscale(0%);
      position: relative;
      cursor: pointer;

      background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0),
          #01497c
        ),
        url(${tooba});
      background-repeat: no-repeat;
      background-size: cover;
    }
  `;
  const ImageContainerYasoob = styled.div`
    @media only screen and (max-width: 768px) {
      height: 400px;
      // width: 250px;
      background-size: cover;
    }
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
    transition: 1s;
    background-image: url(${yasoob});
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 50%;
    .test {
      display: none;
    }
    &:hover {
      .test {
        display: block;
        color: white;
        position: absolute;
        text-align: center;
      }
      filter: grayscale(0%);
      position: relative;
      cursor: pointer;

      background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0),
          #01497c
        ),
        url(${yasoob});
      background-repeat: no-repeat;
      background-size: cover;
    }
  `;

  const ImageContainerThree = styled.div`
    @media only screen and (max-width: 768px) {
      height: 400px;
      // width: 250px;
      background-size: cover;
    }
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
    transition: 1s;
    background-image: url(${akber});
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 50%;
    .test {
      display: none;
    }
    &:hover {
      .test {
        display: block;
        color: white;
        position: absolute;
        text-align: center;
      }
      filter: grayscale(0%);
      position: relative;
      cursor: pointer;

      background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0),
          #01497c
        ),
        url(${akber});
      background-repeat: no-repeat;
      background-size: cover;
    }
  `;
  const ImageContainerTwo = styled.div`
    @media only screen and (max-width: 768px) {
      height: 400px;
      // width: 250px;
      background-size: cover;
    }
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
    transition: 1s;
    background-image: url(${anwar});
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 50%;
    .test {
      display: none;
    }
    &:hover {
      .test {
        display: block;
        color: white;
        position: absolute;
        text-align: center;
      }
      filter: grayscale(0%);
      position: relative;
      cursor: pointer;

      background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0),
          #01497c
        ),
        url(${anwar});
      background-repeat: no-repeat;
      background-size: cover;
    }
  `;

  const ImageContainerHassam = styled.div`
    @media only screen and (max-width: 768px) {
      height: 400px;
      // width: 250px;
      background-size: cover;
    }
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
    transition: 1s;
    background-image: url(${hassam});
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 50%;
    .test {
      display: none;
    }
    &:hover {
      .test {
        display: block;
        color: white;
        position: absolute;
        text-align: center;
      }
      filter: grayscale(0%);
      position: relative;
      cursor: pointer;

      background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0),
          #01497c
        ),
        url(${hassam});
      background-repeat: no-repeat;
      background-size: cover;
    }
  `;
  return (
    <>
      <div
        style={{ width: "100%", border: "1px solid white", paddingTop: "9vh" }}
        id="team"
      >
        <MainContainer>
          <Grid
            container
            spacing={2}
            style={{
              display: "flex",
              justifyContent: "center",
              width: "86%",
              border: "0px solid red",
              marginLeft: "0px",
            }}
          >
            <Grid item lg={12}>
              <HeadingStyle>Meet the Clan</HeadingStyle>
            </Grid>
          </Grid>
        </MainContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MainContainer
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              border: "0px solid blue",
              width: "100%",
            }}
          >
            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                border: "0px solid red",
              }}
            >
              <Grid
                item
                xs={8}
                sm={4}
                md={4}
                lg={1}
                xl={1}
                xxl={1}
                style={{ border: "0px solid green" }}
              >
                <ImageContainerOne s>
                  <img
                    style={{ visibility: "hidden" }}
                    src={teampic}
                    width={"100%"}
                    height={"100%"}
                  />

                  <div className={"test"}>
                    <div>Saad</div>
                    <div>Farhan</div>
                    <br />
                    <p style={{ textAlign: "center" }}> Team Lead </p>
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item>
                        <a
                          target="_blank"
                          href={teamData[0].github}
                          rel="noopener noreferrer"
                        >
                          <BsGithub size={22} color="black" />
                        </a>
                      </Grid>
                      <Grid item>
                        <a
                          target="_blank"
                          href={teamData[0].linked}
                          rel="noopener noreferrer"
                        >
                          <BsLinkedin size={22} color="#0072b1" />
                        </a>
                      </Grid>
                    </Grid>
                    <br />
                  </div>
                </ImageContainerOne>
              </Grid>
              <Grid item xs={8} sm={4} md={4} lg={1}>
                <ImageContainerTwo>
                  <img
                    style={{ visibility: "hidden" }}
                    src={teampic}
                    width={"100%"}
                    height={"100%"}
                  />

                  <div className={"test"}>
                    <div>Saad</div>
                    <div>Anwar</div>
                    <br />
                    <p style={{ textAlign: "center" }}> Team Lead </p>
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item>
                        <a
                          target="_blank"
                          href={teamData[1].github}
                          rel="noopener noreferrer"
                        >
                          <BsGithub size={22} color="black" />
                        </a>
                      </Grid>
                      <Grid item>
                        <a
                          target="_blank"
                          href={teamData[1].linked}
                          rel="noopener noreferrer"
                        >
                          <BsLinkedin size={22} color="#0072b1" />
                        </a>
                      </Grid>
                    </Grid>
                    <br />
                  </div>
                </ImageContainerTwo>
              </Grid>
              <Grid item xs={8} sm={4} md={4} lg={1}>
                <ImageContainerHassam>
                  <img
                    style={{ visibility: "hidden" }}
                    src={teampic}
                    width={"100%"}
                    height={"100%"}
                  />
                  <div className={"test"}>
                    <div>Saad</div>
                    <div>Hassam</div>
                    <br />
                    <p style={{ textAlign: "center" }}> Creative Lead </p>
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item>
                        <a
                          target="_blank"
                          href={teamData[2].github}
                          rel="noopener noreferrer"
                        >
                          <FaArtstation size={18} color="black" />
                        </a>
                      </Grid>
                      <Grid item>
                        <a
                          target="_blank"
                          href={teamData[2].linked}
                          rel="noopener noreferrer"
                        >
                          <BsLinkedin size={22} color="#0072b1" />
                        </a>
                      </Grid>
                    </Grid>
                    <br />
                  </div>
                </ImageContainerHassam>
              </Grid>

              <Grid item xs={8} sm={4} md={4} lg={1.2}>
                <ImageContainerAbubakar>
                  <img
                    style={{ visibility: "hidden" }}
                    src={teampic}
                    width={"100%"}
                    height={"100%"}
                  />
                  <div className={"test"}>
                    <div>Abubakar</div>
                    <div>Ijaz</div>
                    <br />
                    <p style={{ textAlign: "center" }}> SQA </p>
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item>
                        <a
                          target="_blank"
                          href={teamData[3].github}
                          rel="noopener noreferrer"
                        >
                          <BsGithub size={22} color="black" />
                        </a>
                      </Grid>
                      <Grid item>
                        <a
                          target="_blank"
                          href={teamData[3].linked}
                          rel="noopener noreferrer"
                        >
                          <BsLinkedin size={22} color="#0072b1" />
                        </a>
                      </Grid>
                    </Grid>
                    <br />
                  </div>
                </ImageContainerAbubakar>
              </Grid>
              <Grid item xs={8} sm={4} md={4} lg={1.2}>
                <ImageContainerThree>
                  <img
                    style={{ visibility: "hidden" }}
                    src={teampic}
                    width={"100%"}
                    height={"100%"}
                  />
                  <div className={"test"}>
                    <div>Akber</div>
                    <div>Taj</div>
                    <br />
                    <p style={{ textAlign: "center" }}>UI/UX </p>
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item>
                        <a
                          target="_blank"
                          href={teamData[4].github}
                          rel="noopener noreferrer"
                        >
                          <BsBehance size={18} color="black" />
                        </a>
                      </Grid>
                      <Grid item>
                        <a
                          target="_blank"
                          href={teamData[4].linked}
                          rel="noopener noreferrer"
                        >
                          <BsLinkedin size={22} color="#0072b1" />
                        </a>
                      </Grid>
                    </Grid>
                    <br />
                  </div>
                </ImageContainerThree>
              </Grid>
              <Grid item xs={8} sm={4} md={4} lg={1.2}>
                <ImageContainerAebad>
                  <img
                    style={{ visibility: "hidden" }}
                    src={teampic}
                    width={"100%"}
                    height={"100%"}
                  />
                  <div className={"test"}>
                    <div>Aebad Ul </div>
                    <div>Quadir</div>
                    <br />
                    <p style={{ textAlign: "center" }}>Software Engineer</p>
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item>
                        <a
                          target="_blank"
                          href={teamData[5].github}
                          rel="noopener noreferrer"
                        >
                          <BsGithub size={22} color="black" />
                        </a>
                      </Grid>
                      <Grid item>
                        <a
                          target="_blank"
                          href={teamData[5].linked}
                          rel="noopener noreferrer"
                        >
                          <BsLinkedin size={22} color="#0072b1" />
                        </a>
                      </Grid>
                    </Grid>
                    <br />
                  </div>
                </ImageContainerAebad>
              </Grid>
              <Grid item xs={8} sm={4} md={4} lg={1.2}>
                <ImageContainerWahaj>
                  <img
                    style={{ visibility: "hidden" }}
                    src={teampic}
                    width={"100%"}
                    height={"100%"}
                  />
                  <div className={"test"}>
                    <div>Wahaj</div>
                    <div>Rashid</div>
                    <br />
                    <p style={{ textAlign: "center" }}>Frontend Engineer</p>
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item>
                        <a
                          target="_blank"
                          href={"https://github.com/WahajRashid1020"}
                          rel="noopener noreferrer"
                        >
                          <BsGithub size={22} color="black" />
                        </a>
                      </Grid>
                      <Grid item>
                        <a
                          target="_blank"
                          href={
                            "https://www.linkedin.com/in/wahaj-rashid-0a098a214/"
                          }
                          rel="noopener noreferrer"
                        >
                          <BsLinkedin size={22} color="#0072b1" />
                        </a>
                      </Grid>
                    </Grid>
                    <br />
                  </div>
                </ImageContainerWahaj>
              </Grid>

              <Grid item xs={8} sm={4} md={4} lg={1.2}>
                <ImageContainerYasoob>
                  <img
                    style={{ visibility: "hidden" }}
                    src={teampic}
                    width={"100%"}
                    height={"100%"}
                  />
                  <div className={"test"}>
                    {" "}
                    <div>Syed</div>
                    <div>Yasoob</div>
                    <br />
                    <p style={{ textAlign: "center" }}>
                      Backend <br /> Engineer
                    </p>
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item>
                        <a
                          target="_blank"
                          href={"https://github.com/Yasoobnaqvi"}
                          rel="noopener noreferrer"
                        >
                          <BsGithub size={22} color="black" />
                        </a>
                      </Grid>
                      <Grid item>
                        <a
                          target="_blank"
                          href={
                            "https://www.linkedin.com/in/yasoob-naqvi-23ba2021a/"
                          }
                          rel="noopener noreferrer"
                        >
                          <BsLinkedin size={22} color="#0072b1" />
                        </a>
                      </Grid>
                    </Grid>
                    <br />
                  </div>
                </ImageContainerYasoob>
              </Grid>

              {/* <Grid item xs={8} sm={4} md={4} lg={1.2}>
                <ImageContainerTooba>
                  <img
                    style={{ visibility: "hidden" }}
                    src={teampic}
                    width={"100%"}
                    height={"100%"}
                  />
                  <div className={"test"}>
                    <div>Tooba</div>
                    <div>Ali</div>
                    <br />
                    <p style={{ textAlign: "center" }}>Business Analyst</p>
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item>
                        <a
                          target="_blank"
                          href={"https://www.linkedin.com/in/tooba-aly/"}
                          rel="noopener noreferrer"
                        >
                          <BsLinkedin size={22} color="#0072b1" />
                        </a>
                      </Grid>
                    </Grid>
                    <br />
                  </div>
                </ImageContainerTooba>
              </Grid> */}
            </Grid>
          </MainContainer>
        </div>
      </div>
    </>
  );
};

export default MeetClan;
