import React from "react";
import { Navbar } from "../../components/Navbar/navbar";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Contact from "../../components/Contact";
import OtherProject from "../../components/OtherProject";
import MeetClan from "../../components/MeetClan";
import OurProject from "../../components/OurProject";
import styled from "styled-components";
import Scroll from "../../components/Slides";

import "../../App.css";
export const Home = () => {
  const Div = styled.div`
    display: flex;
    align-items: center;
    padding-top: 20vh;
    padding-bottom: 20vh;

    @media only screen and (min-width: 2500px) {
      /* padding-top: 50vh; */
      /* padding-bottom: 50vh; */
    }
    @media only screen and (max-width: 375px) {
      padding-top: 0vh;
    }
  `;

  const MeetDiv = styled.div`
    padding-top: 20vh;
    display: flex;
    align-items: center;
    padding-bottom: 20vh;
    @media only screen and (max-width: 375px) {
      padding-top: 0vh;
    }
  `;
  const ScrollDiv = styled.div`
    height: 650vh;
    padding-top: 20vh;
    @media only screen and (max-width: 1700px) {
      /* height: 740vh; */
      height: 660vh;
    }
    @media only screen and (max-width: 1500px) {
      /* height: 740vh; */
      height: 690vh;
    }
    @media only screen and (max-width: 1300px) {
      margin-bottom: 20vh;
      height: 680vh;
    }
    @media only screen and (max-width: 900px) {
      margin-bottom: 20vh;
      height: 690vh;
    }
    @media only screen and (max-width: 768px) {
      height: 760vh;
    }
    @media only screen and (max-width: 600px) {
      height: 750vh;
    }

    @media only screen and (max-width: 375px) {
      padding-top: 0vh;
      height: 725vh;
    }
  `;
  const OurProjectDiv = styled.div`
    padding-top: 20vh;
    display: flex;
    align-items: center;
    padding-bottom: 20vh;
    padding-top: 30vh;
    @media only screen and (max-width: 425px) {
      padding-top: 0vh;
      
    }
    @media only screen and (max-width: 375px) {
      padding-top: 0vh;
      
    }
  `;

  const OtherProjeDiv = styled.div`
    padding-top: 20vh;
    display: flex;
    align-items: center;
    padding-bottom: 20vh;
    @media only screen and (max-width: 375px) {
      padding-top: 0vh;
    }
  `;
  return (
    <>
      <Navbar />
      <div id="home">
        <Header />
      </div>
      <ScrollDiv>
        <Scroll />
      </ScrollDiv>
      <OurProjectDiv>
        <OurProject />
      </OurProjectDiv>
      <OtherProjeDiv id="projects">
        <OtherProject />
      </OtherProjeDiv>
      <MeetDiv>
        <MeetClan />
      </MeetDiv>
      <Div>
        <Contact />
      </Div>

      <Footer />
    </>
  );
};
