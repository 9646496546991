import React from "react";
import { Grid } from "@mui/material";
import workspace from "../../assets/workspace.webp";
import {
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import styled from "styled-components";
import CCLogoCrop from "../../assets/CCLogoCrop.png";
const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;

  background-color: #01497c;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

const FlexLeft = styled.div`
  flex: 40%;
  padding: 12vh;

  color: white;
  @media (max-width: 1100px) {
    padding: 4vh;
    order: 1;
  }
`;
const FlexCenter = styled.div`
  flex: 20%;
  padding: 4vh;

  @media (max-width: 1100px) {
    padding: 4vh;
    order: 0;
  }
`;

const FlexRight = styled.div`
  flex: 40%;
  padding: 12vh;
  color: white;

  @media (max-width: 1100px) {
    padding: 4vh;
    order: 2;
  }
`;
const LineBox = styled.div`
  display: flex;
  justify-content: center;
  height: 60%;
  @media (max-width: 1100px) {
    height: 10;
    margin-bottom: 10px;
  }
`;
const Line = styled.div`
  border: 1px solid white;
  width: 2px;
  height: 80%;
  @media (max-width: 1100px) {
    width: 6rem;
  }
`;
const IconMain = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 20%;
`;
const IconBox = styled.div``;

const CopyWrite = styled.div`
  background-color: #01497c;
  padding: 1vh;
  @media (max-width: 1100px) {
    padding: 4vh;
  }
`;

const Footer = () => {
  return (
    <>
      <img
        src={workspace}
        width={"100%"}
        height={"564px"}
        style={{ marginBottom: "5vh", objectFit: "cover" }}
      />
      <FlexContainer>
        <FlexLeft>
          <img
            src={CCLogoCrop}
            width={60}
            height={60}
            style={{ marginBottom: "1rem" }}
          />
          <p>+92 3089216362</p>
          <p>saadfarhan@codeclan.io</p>
          <p>House # A144/A, Block-5 Gulshan-e-Iqbal, Karachi, Pakistan</p>
        </FlexLeft>
        <FlexCenter>
          <div>
            <p
              style={{
                fontWeight: 700,
                fontSize: "30px",
                color: "white",
                textAlign: "center",
              }}
            >
              Contact us
            </p>
          </div>
          <div></div>
          <LineBox>
            <Line></Line>
          </LineBox>

          <IconMain>
            <IconBox>
              <a
                style={{
                  background: "white",
                  color: "#01497C",

                  display: " inline-block",
                  borderRadius: "60px",

                  padding: " 0.5em 0.8em",
                  marginRight: "10px",
                }}
                target="_blank"
                href={"https://www.facebook.com/profile.php?id=100086581724979"}
                rel="noopener noreferrer"
              >
                <FaFacebookF />
              </a>
              <a
                style={{
                  background: "white",
                  color: "#01497C",

                  display: " inline-block",
                  borderRadius: "60px",

                  padding: " 0.5em 0.8em",
                  marginRight: "10px",
                }}
                target="_blank"
                href={"https://www.linkedin.com/company/code-clan-io/about/"}
                rel="noopener noreferrer"
              >
                <FaLinkedinIn
                  style={{ marginLeft: "1px", marginBottom: "1px" }}
                />
              </a>
              <a
                style={{
                  background: "white",
                  color: "#01497C",

                  display: " inline-block",
                  borderRadius: "60px",

                  padding: " 0.5em 0.8em",
                  marginRight: "10px",
                }}
                target="_blank"
                href={"https://www.instagram.com/codeclan.io/"}
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </a>
              <a
                style={{
                  background: "white",
                  color: "#01497C",

                  display: " inline-block",
                  borderRadius: "60px",

                  padding: " 0.5em 0.8em",
                }}
                target="_blank"
                href={"https://twitter.com/CodeClan_io"}
                rel="noopener noreferrer"
              >
                <FaTwitter />
              </a>
            </IconBox>
          </IconMain>
        </FlexCenter>
        <FlexRight>
          Say hello to Code Clan, your tech BFF! We're a team of software
          development wizards who specialize in creating amazing mobile apps and
          websites. Our team is made up of the crème de la crème of tech talent,
          so you know you're in good hands. If you're ready to take your tech
          game to the next level, give us a shout. We can't wait to work our
          magic for you!
        </FlexRight>
      </FlexContainer>
      <CopyWrite>
        <Grid
          alignItems="center"
          justifyContent="center"
          container
          spacing={2}
          padding="1vh"
        >
          <Grid item>
            <div style={{ textAlign: "center" }}>
              <p style={{ color: "white" }}>
                Copyright &copy; {new Date().getFullYear()}{" "}
                <span style={{ color: "white" }}>CodeClan</span>. All rights
                reserved.
              </p>
            </div>
          </Grid>
        </Grid>
      </CopyWrite>
    </>
  );
};

export default Footer;
