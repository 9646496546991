import React, { useEffect, useState } from "react";
import "../../App.css";
import UIUX from "../../assets/UIUX2.webp";
import SecTwoImg from "../../assets/sectwoimg.webp";
import Mobile from "../../assets/webapp.webp";
import Ecommerce from "../../assets/ecommerce.webp";
import Api from "../../assets/apiimg.webp";
import styled from "styled-components";
import Game from "../../assets/game.webp";
import Devops from "../../assets/devops.webp";
import { fontSize } from "@mui/system";
const Scroll = () => {
  const [shadow, setShadow] = useState(1);

  useEffect(() => {
    document.addEventListener("scroll", checkScroll);
    return () => document.removeEventListener("scroll", checkScroll);
  }, []);

  const checkScroll = (e) => {
    if (window.innerWidth <= 767) {
      if (window.scrollY >= 0 && window.scrollY <= 1600) {
        setShadow(1);
      } else if (window.scrollY >= 1601 && window.scrollY <= 4040) {
        setShadow(2);
      } else if (window.scrollY >= 4041) {
        setShadow(3);
      }
    } else if (window.innerWidth >= 768 && window.innerWidth <= 1023) {
      if (window.scrollY >= 0 && window.scrollY <= 1325) {
        setShadow(1);
      } else if (window.scrollY >= 1326 && window.scrollY <= 4000) {
        setShadow(2);
      } else if (window.scrollY >= 4001) {
        setShadow(3);
      }
    } else if (window.innerWidth >= 1024) {
      if (window.scrollY >= 0 && window.scrollY <= 1130) {
        setShadow(1);
      } else if (window.scrollY >= 1131 && window.scrollY <= 4399) {
        setShadow(2);
      } else if (window.scrollY >= 4400) {
        setShadow(3);
      }
    }
  };

  const Conti = styled.div`
    height: 100vh;
  `;

  const SliderMajor = styled.div`
    color: #044c7d;
    font-weight: 600;
    font-size: 25pt;
    width: 100%;
    text-align: left;
    padding-bottom: 1rem;
    @media only screen and (max-width: 1250px) {
      font-size: 28px;
      text-align: center;
    }
    @media only screen and (max-width: 768px) {
      font-size: 27px;
      text-align: center;
    }
    @media only screen and (max-width: 425px) {
      font-size: 25px;
      text-align: center;
    }
  `;
  const SliderHead = styled.div`
    text
    font-weight: 600;
    font-size: 20pt;
    color: "black";
    width: 100%;
    text-align: left;
    padding-bottom: 1rem;
    @media only screen and (max-width: 1250px) {
      font-size: 18px;
      text-align:center;
    }
    @media only screen and (max-width: 768px) {
      font-size: 17px;
      text-align:center;
    }
    @media only screen and (max-width: 425px) {
      font-size: 15px;
      text-align:center;
    }
  `;

  const SlidePara = styled.div`
    font-size: 16px;

    /* hyphens: auto; */
    word-spacing: -0.05rem;
    text-align: justify;
    width: 100%;
    padding-right: 2rem;

    @media only screen and (max-width: 940px) {
      font-size: 15px;
    }

    @media only screen and (max-width: 680px) {
      font-size: 13px;
      padding: 0px;
    }
    @media only screen and (max-width: 590px) {
      font-size: 12px;
      padding: 0px;
    }
  `;
  const MainCont = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    @media only screen and (max-width: 900px) {
      flex-direction: column;
    }
  `;
  const ContOne = styled.div`
    flex: 1;

    background-image: url(${Mobile});
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: 50%;
    background-position-x: 50%;
  `;
  const ContOneOne = styled.div`
    flex: 1;

    background-image: url(${SecTwoImg});
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: 50%;
    background-position-x: 50%;
  `;
  const CountUiUx = styled.div`
    flex: 1;

    background-image: url(${UIUX});
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: 50%;
    background-position-x: 50%;
  `;
  const ContOneThree = styled.div`
    flex: 1;

    background-image: url(${Devops});
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: 50%;
    background-position-x: 50%;
  `;
  const ContApi = styled.div`
    flex: 1;

    background-image: url(${Api});
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: 50%;
    background-position-x: 50%;
  `;
  const ContOneEcom = styled.div`
    flex: 1;

    background-image: url(${Ecommerce});
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: 50%;
    background-position-x: 50%;
  `;
  const ContMulti = styled.div`
    flex: 1;

    background-image: url(${Game});
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: 50%;
    background-position-x: 50%;
  `;

  const ContTwo = styled.div`
    flex: 1;

    @media only screen and (max-width: 680px) {
      padding: 0px;
    }
    @media only screen and (max-width: 590px) {
      padding: 0px;
    }
  `;

  const Title = styled.p`
    font-size: 25pt;
    font-weight: 700;
    line-height: 78px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0px;
    margin-top: 4vh;
    @media only screen and (max-width: 768px) {
      font-size: 28px;
    }
    @media screen and (max-width: 644px) {
      font-size: 27px;
    }
    @media screen and (max-width: 400px) {
      text-align: center !important;
      font-size: 25px;
    }
  `;

  return (
    <Conti style={{ paddingTop: "0vh" }} id="service">
      <div className="sticky" id="bar">
        <Title id="t1" className="text1">
          OUR SERVICES
        </Title>
      </div>
      <div className="mySwiper">
        <div className="slides">
          <MainCont>
            <ContTwo>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                  paddingLeft: "0.5rem",
                  alignContent: "center",
                  margin: "2px",
                }}
              >
                <SliderMajor>Mobile App Design and Development</SliderMajor>

                <SliderHead>A Blast to Launch</SliderHead>
                <SlidePara>
                  We've Got You Covered. Our services bring your app vision to
                  life with lightning speed, a sleek design, and an intuitive
                  user experience. Our tech-savvy team is ready to tackle
                  complex challenges, delivering top-notch mobile app
                  development services.
                </SlidePara>
              </div>
            </ContTwo>
            <ContOne></ContOne>
          </MainCont>
        </div>

        <div className="slides">
          <MainCont>
            <ContTwo>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",

                  height: "100%",
                  paddingLeft: "0.5rem",
                  alignContent: "center",
                  margin: "2px",
                }}
              >
                <SliderMajor>Web Design & Development</SliderMajor>
                <SliderHead>A Site to Remember!</SliderHead>
                <SlidePara>
                  Our team of web wizards creates visually stunning and
                  user-friendly websites that leave a lasting impression. From
                  design to functionality, we've got it all covered. Upgrade
                  your online presence with a website that's out of this world.
                  Contact us today and let's cast a spell!
                </SlidePara>
              </div>
            </ContTwo>
            <ContOneOne></ContOneOne>
          </MainCont>
        </div>
        <div className="slides">
          <MainCont>
            <ContTwo>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",

                  height: "100%",
                  paddingLeft: "0.5rem",
                  alignContent: "center",
                  margin: "2px",
                }}
              >
                <SliderMajor>UI/UX </SliderMajor>
                <SliderHead>A User Experience Like No Other!</SliderHead>
                <SlidePara>
                  Our team delivers an exceptional user experience for your
                  digital product with intuitive navigation and visually
                  appealing designs. Upgrade your product with a user experience
                  that's out of this world. Contact us today and let's get
                  started!
                </SlidePara>
              </div>
            </ContTwo>
            <CountUiUx></CountUiUx>
          </MainCont>
        </div>
        <div className="slides">
          <MainCont>
            <ContTwo>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",

                  height: "100%",
                  paddingLeft: "0.5rem",
                  alignContent: "center",
                  margin: "2px",
                }}
              >
                <SliderMajor>DevOps</SliderMajor>
                <SliderHead>Streamlining Your Technology Workflow</SliderHead>
                <SlidePara>
                  We are here to streamline your processes and optimize your
                  system performance. With our team of experts, you'll have a
                  technology infrastructure that's reliable, efficient, and
                  scalable. From continuous integration to automated deployment,
                  we've got it all covered. We understand the challenges of
                  managing a complex technology environment and we're here to
                  make it simple and seamless.
                </SlidePara>
              </div>
            </ContTwo>
            <ContOneThree></ContOneThree>
          </MainCont>
        </div>
        <div className="slides">
          <MainCont>
            <ContTwo>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",

                  height: "100%",
                  paddingLeft: "0.5rem",
                  alignContent: "center",
                  margin: "2px",
                }}
              >
                <SliderMajor>API Development</SliderMajor>
                <SliderHead>Connecting Your Systems and Services</SliderHead>
                <SlidePara>
                  Ready to connect your systems and services seamlessly? We are
                  here to make that happen. With our team of experts, you'll
                  have a robust and scalable API infrastructure that integrates
                  with your existing systems and supports your business needs.
                  From design to deployment, we've got it all covered. We
                  understand the complexities of API development and we're here
                  to make it simple and efficient.
                </SlidePara>
              </div>
            </ContTwo>
            <ContApi></ContApi>
          </MainCont>
        </div>
        <div className="slides">
          <MainCont>
            <ContTwo>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",

                  height: "100%",
                  paddingLeft: "0.5rem",
                  alignContent: "center",
                  margin: "2px",
                }}
              >
                <SliderMajor>Ecommerce / WordPress Development</SliderMajor>
                <SliderHead>Powering Your Online Store</SliderHead>
                <SlidePara>
                  Invest in e-commerce development solutions to boost your
                  business. Developers at Code clan create custom storefronts
                  and digital solutions for back-office e-commerce operations.
                  We are the right match for you if you are tired of constant
                  patches, unreliable consultants, and micromanagement.
                </SlidePara>
              </div>
            </ContTwo>
            <ContOneEcom></ContOneEcom>
          </MainCont>
        </div>

        <div className="slides">
          <MainCont>
            <ContTwo>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",

                  height: "100%",
                  paddingLeft: "0.5rem",
                  alignContent: "center",
                  margin: "2px",
                }}
              >
                <SliderMajor>Multi - Platforms Game</SliderMajor>
                <SliderHead>Elevate Your Gaming Experience!</SliderHead>
                <SlidePara>
                  Our services specialize in creating a seamless gaming
                  experience that can be enjoyed on multiple platforms and
                  devices. From idea to launch, our team has the expertise to
                  turn your vision into a reality. Elevate your gaming
                  experience by reaching a wider audience across multiple
                  platforms.
                </SlidePara>
              </div>
            </ContTwo>
            <ContMulti></ContMulti>
          </MainCont>
        </div>
      </div>
    </Conti>
  );
};

export default Scroll;
