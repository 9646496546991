import { Button, Grid, TextField } from "@mui/material";
import { Element } from "react-scroll";
import styled from "styled-components";
import emailjs from "@emailjs/browser";
import React, { useState } from "react";
export default function Contact() {
  const [thanks, setThanks] = useState(null);
  const HeadingStyle = styled.p`
    font-size: 25pt;
    font-weight: 700;
    line-height: 78px;
    letter-spacing: 0em;
    text-align: center;
    padding: 0px;
    margin-top: 4vh;
    @media only screen and (max-width: 768px) {
      font-size: 28px;
    }
    @media screen and (max-width: 644px) {
      font-size: 27px;
    }
    @media screen and (max-width: 400px) {
      font-size: 25px;
    }
  `;
  const ParagraphStyle = styled.p`
    font-size: 20pt;
    font-weight: 400;

    letter-spacing: 0em;
    text-align: center;
    padding: 0px;
    margin-bottom: 20px;

    @media only screen and (max-width: 768px) {
      text-align: center;

      font-size: 18px;
    }
    @media screen and (max-width: 644px) {
      text-align: center;

      font-size: 17px;
    }
    @media screen and (max-width: 400px) {
      text-align: center;

      font-size: 15px;
    }
  `;
  const Koko = styled.div`
    @media only screen and (min-width: 2500px) {
      padding-top: 20vh;
      padding-bottom: 20vh;
    }
    padding-inline: 2rem;
  `;
function sendEmail(e) {
  e.preventDefault(); // prevent the form from submitting normally
  const formData = new FormData(e.target); // create a FormData object from the form
  const data = {}; // create an empty object to store the form data
  for (const [name, value] of formData.entries()) {
    data[name] = value; // store the value for each input field in the data object
  }
  data["website"] = "codeclan.io";
  emailjs
    .send(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      data,
      process.env.REACT_APP_PUBLIC_ID
    )
   .then(
        (result) => {
          setThanks(result.status);
        },
        (error) => {}
      );
  
        e.target.reset();

}

  

  return (
    <div style={{ width: "100%", paddingTop: "5vh" }} id="contact">
      <Element name="contactus">
        <Koko>
          <Grid
            alignItems="center"
            justifyContent="center"
            container
            spacing={2}
          >
            <Grid item xs={12} md={6}>
              <ParagraphStyle>
                Got a few queries that need answering?
              </ParagraphStyle>
              <ParagraphStyle>Get in touch with us.</ParagraphStyle>
              <form onSubmit={sendEmail}>
                <Grid alignItems="center" container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      style={{ width: "100%" }}
                      id="outlined-basic"
                      label="First Name"
                      variant="outlined"
                      name="name"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      style={{ width: "100%" }}
                      id="outlined-basic"
                      label="Last Name"
                      variant="outlined"
                      name="lastName"
                    />
                  </Grid>
                </Grid>
                <Grid
                  alignItems="center"
                  container
                  spacing={2}
                  style={{ marginTop: 0 }}
                >
                  <Grid item xs={12} md={6}>
                    <TextField
                      style={{ width: "100%" }}
                      id="outlined-basic"
                      label="Email Address"
                      variant="outlined"
                      name="email"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      style={{ width: "100%" }}
                      id="outlined-basic"
                      label="Phone No"
                      variant="outlined"
                      name="phone"
                    />
                  </Grid>
                </Grid>
                <Grid
                  justifyContent="center"
                  spacing={2}
                  container
                  style={{ marginTop: 0 }}
                >
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="outlined-basic"
                      style={{ width: "100%" }}
                      rows={4}
                      label="Message"
                      multiline
                      variant="outlined"
                      name="message"
                    />
                  </Grid>
                </Grid>
                <Grid
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  container
                  style={{ marginTop: 0 }}
                >
                  <Grid item xs={3} md={3}>
                    <Button
                      type="submit"
                      variant="contained"
                      style={{ background: "#01497c", width: "100%" }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
                {thanks === 200 ? (
                  <p style={{ fontWeight: "600", color: "#01497c" }}>
                    Thank you for contacting us. Our team will get in touch with you shortly{" "}
                  </p>
                ) : null}
              </form>
            </Grid>
          </Grid>
        </Koko>
      </Element>
    </div>
  );
}
